/* eslint-disable @typescript-eslint/default-param-last */
import { UPDATE_SELECTED_PROPERTY } from '../../actions/types';

// Initial state
const emptyState = {};

export default (state = emptyState, action: any) => {
  switch (action.type) {
    case UPDATE_SELECTED_PROPERTY:
      return {
        ...emptyState,
        ...action.data,
      };
    default:
      return state;
  }
};
