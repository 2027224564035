import { ApolloProvider } from '@apollo/client';
import { useAuth } from '@tailosinc/bender-auth-react-cognito-hook';
import PropTypes from 'prop-types';
import React from 'react';

import getClientSwitch from '../../main-apollo-client';

export const ApolloProviderWrapperComponent = ({ children }: any) => {
  const auth = useAuth();
  return (
    <ApolloProvider client={getClientSwitch()(auth)}>
      {children}
    </ApolloProvider>
  );
};

ApolloProviderWrapperComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApolloProviderWrapperComponent;
