/* eslint-disable @typescript-eslint/default-param-last */
import {
  SIDEBAR_MICRO_APP_REMOVE,
  SIDEBAR_MICRO_APP_SET,
} from '../../actions/sidebarMicroApp/types';

// Initial state
const emptyState = {};

export default (state = emptyState, action: any) => {
  switch (action.type) {
    case SIDEBAR_MICRO_APP_SET:
      return {
        ...emptyState,
        ...action.data,
      };
    case SIDEBAR_MICRO_APP_REMOVE:
      return emptyState;
    default:
      return state;
  }
};
