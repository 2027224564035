import { configureStore } from '@reduxjs/toolkit';
import { dataContextUpdated } from '@tailosinc/bender-core';

import reducers from './reducers';

// // call bender dataContextUpdated when state changes
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleStateChange = (_prevState: any, _nextState: any, _action: any, _dispatch: any) => {
  dataContextUpdated();
};

// // on redux state change middleware
const onStateChange = (fn: any) => (store: any) => (next: any) => (action: any) => {
  const prevState = store.getState();
  const result = next(action);
  const nextState = store.getState();
  fn(prevState, nextState, action, store.dispatch);
  return result;
};

const emptyState = {};

export default configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware: any) => getDefaultMiddleware()
    .prepend(onStateChange(handleStateChange)),
  preloadedState: emptyState,
});
