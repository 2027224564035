/* eslint-disable @typescript-eslint/default-param-last */
import {
  ACTIVE_SIDEBAR_REMOVE,
  ACTIVE_SIDEBAR_SET,
} from '../../actions/activeSidebar/types';

export const emptyState = null;

export default (state = emptyState, action: any) => {
  switch (action.type) {
    case ACTIVE_SIDEBAR_SET:
      return {
        ...action.data,
      };
    case ACTIVE_SIDEBAR_REMOVE:
      return emptyState;
    default:
      return state;
  }
};
