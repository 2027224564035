import * as bender from '@tailosinc/bender-core';
import React from 'react';

const withBender = (Component: any) => {
  const wrapper = (props: any) => <Component {...props} bender={bender} />;
  wrapper.displayName = `withBender(${Component.displayName || Component.name})`;
  return wrapper;
};

export default withBender;
