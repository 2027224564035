import {
  UPDATE_SELECTED_PROPERTY,
} from './types';

export const updateSelectedProperty = (property: any) => ({
  type: UPDATE_SELECTED_PROPERTY,
  data: {
    ...property,
  },
});

export default updateSelectedProperty;
