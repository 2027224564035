/* eslint-disable @typescript-eslint/default-param-last */
import {
  LOGIN_SUCCESS,
  LOGOUT,
  TOKEN_REFRESHED,
} from '../../actions/types';

// Initial state
const emptyState = {};

export default (state = emptyState, action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...emptyState,
        ...action.data,
      };
    case TOKEN_REFRESHED:
      return {
        ...emptyState,
        ...action.data,
      };
    case LOGOUT:
      return {
        ...state,
        logout: true,
      };
    default:
      return state;
  }
};
