import 'isomorphic-fetch';

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export const introspectionQueryResultData = {
  __schema: {
    types: [],
  },
};

const getClient = (auth: any) => {
  const { jwtToken } = auth.user.signInUserSession.idToken;
  const httpLink = createHttpLink({ uri: process.env.GRAPHQL_ENDPOINT });
  const authMiddleware = setContext(() => ({
    headers: {
      authorization: jwtToken,
    },
  }));

  // https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically
  const httpLinkWithAuthToken = authMiddleware.concat(httpLink);
  return new ApolloClient({
    link: httpLinkWithAuthToken,
    cache: new InMemoryCache({ }),
  });
};

export default getClient;
