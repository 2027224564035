import { LOGIN_SUCCESS, LOGOUT, TOKEN_REFRESHED } from './types';

const buildData = (auth: any, userAccount: any) => {
  const { payload } = auth.getIdToken();
  const response = {
    defaultProperty: payload['custom:defaultProperty'],
    username: payload['cognito:username'],
    currentProperty: payload.currentProperty || payload['custom:defaultProperty'],
    accountDetails: userAccount,
    firstName: payload.given_name,
    lastName: payload.family_name,
    roles: auth.getRoles(),
    token: auth.getJwtToken(),
    accessToken: auth.getAccessToken().getJwtToken(),
  };
  return response;
};

export const loginSuccess = (auth: any, userAccount: any) => ({
  type: LOGIN_SUCCESS,
  data: buildData(auth, userAccount),
});

export const tokenRefreshed = (auth: any, userAccount: any) => ({
  type: TOKEN_REFRESHED,
  data: buildData(auth, userAccount),
});

export const logout = () => (
  {
    type: LOGOUT,
  }
);
