import 'isomorphic-fetch';

import { dataContextGetter, registerApp } from '@tailosinc/bender-core';

const ABSOLUTE_URL_PATTERN = /^((http|https):\/\/)/;

export const pathPrefix = (prefix: any) => (
  (location: any) => (
    location.pathname.startsWith(`${prefix}`)
  )
);

export const hashPrefix = (prefix: any) => (
  (location: any) => (
    location.hash.startsWith(`#${prefix}`)
  )
);

// checks if bundle uses absolute or relative path
/* istanbul ignore next */
const bundleUrl = (bundlePath: any) => {
  if (!ABSOLUTE_URL_PATTERN.test(bundlePath)) {
    return `${process.env.MICRO_APP_BASE_URL}/${bundlePath}`;
  }
  return bundlePath;
};

/* global System */
export const performMicroAppRegistration = (microApp: any) => {
  // System.config({
  //   map: {
  //     microApp: process.env.MICRO_APP_BASE_URL,
  //   },
  // });
  const customProps: any = {};
  if (microApp.targetElementId) {
    // if we specified element id, we can construct domElementGetter or get domElement
    // to be sent with customProps.  Alternatively, mount lifecycle method of the
    // microApp can specify elementId, or even logic in the component that builds its
    // content based on mount point can update customProps for registered micro app.
    customProps.domElementGetter = /* istanbul ignore next */ () => document.getElementById(microApp.targetElementId);
  }
  // console.log('microapp', System.import(`${microApp.bundle}`));
  if (microApp.targetType === 'ROUTE') {
    let activationFn: any;
    if (microApp.activeWhen.locationExpression) {
      // you can have an expression, for example:
      // location.pathname === "" || location.pathname === "/"
      // eslint-disable-next-line no-new-func
      // eslint-disable-next-line @typescript-eslint/no-implied-eval
      activationFn = new Function('location', `return ${microApp.activeWhen.locationExpression}`);
    } else if (microApp.activeWhen.pathPrefix) {
      activationFn = pathPrefix(microApp.activeWhen.pathPrefix);
    } else if (microApp.activeWhen.hashPrefix) {
      activationFn = hashPrefix(microApp.activeWhen.hashPrefix);
    } else {
      throw new Error('One of locationExpression, pathPrefix or hashPrefix is required for activeWhen');
    }
    registerApp(microApp.name, {
      activationFn,
      loaderFn: /* istanbul ignore next */ () => (microApp.bundleCss
        // @ts-ignore
        ? System.import(bundleUrl(microApp.bundleCss)) && System.import(bundleUrl(microApp.bundle))
        // @ts-ignore
        : System.import(bundleUrl(microApp.bundle))),
      mountPoints: microApp.mountPoints, // you can add additional customProps in here if needed
      customProps,
      shouldOverwrite: microApp.shouldOverwrite,
    });
  } else if (microApp.targetType === 'LINK') {
    let activationFn;
    if (microApp.activeWhen.pathPrefix) {
      activationFn = pathPrefix(microApp.activeWhen.pathPrefix);
    } else {
      throw new Error('PathPrefix is required for activeWhen');
    }
    registerApp(microApp.name, {
      activationFn,
      loaderFn: /* istanbul ignore next */ async () => {
        if (microApp?.storeAuth) {
          const jwt = dataContextGetter()().user.accessToken;
          document.cookie = `jwt=${jwt};max-age=3600;domain=${process.env.COOKIE_DOMAIN}`;
        }
        window.location.href = microApp.link;
      },
      mountPoints: microApp.mountPoints, // you can add additional customProps in here if needed
    });
  } else if (microApp.targetType === 'NON_ROUTE') {
    // this means it will be rendered by calling microFrontend.renderApp in some DOM element.
    // It might or might not contain mountPoints.  If some mointPoint needs to dynamically
    // discover this microApp then it will have mountPoint specific data, otherwise it will not.
    // we set activation to false since it will not be activated by a route change.

    // @ts-ignore
    registerApp(microApp.name, {
      loaderFn: /* istanbul ignore next */ () => (microApp.bundleCss
        // @ts-ignore
        ? System.import(bundleUrl(microApp.bundleCss)) && System.import(bundleUrl(microApp.bundle)).then((module) => {
          // eslint-disable-next-line no-console
          console.log('Bender Imported', module);
          return module;
        })
        // @ts-ignore
        : System.import(bundleUrl(microApp.bundle)).then((module) => {
          // eslint-disable-next-line no-console
          console.log('Bender Imported', module);
          return module;
        })),
      mountPoints: microApp.mountPoints,
      customProps,
    });
  } else if (microApp.targetType === 'ALWAYS_VISIBLE') {
    // @ts-ignore
    registerApp(microApp.name, {
      activationFn: () => true,
      loaderFn: /* istanbul ignore next */ () => (microApp.bundleCss
        // @ts-ignore
        ? System.import(bundleUrl(microApp.bundleCss)) && System.import(bundleUrl(microApp.bundle))
        // @ts-ignore
        : System.import(bundleUrl(microApp.bundle))),
      customProps,
    });
  }
};

/**
 * Loads micro app metadata file and registers each application with microFrontend library.
 * It filters out any micro app that is not enabled or a user does not have permissions to access.
 * @param {*} registryResource - url to get registry file
 * @param {*} loggedInUserPermissions - currently logged in user's permissions array
 */
const registerMicroApps = (registryResource: any, loggedInUserPermissions: any) => {
  // eslint-disable-next-line no-console
  console.log(`Indexing Micro App Registry resource @ ${registryResource}`);
  return fetch(registryResource, { mode: 'cors' })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch registry resource', error);
      throw error;
    })
    .then((response) => {
      if (!response.ok) {
        const error = new Error(`Failed to fetch registry resource (STATUS: ${response.status})`);
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      }
      return response.json();
    })
    .then((registry) => {
      registry.microApps
        .filter((microApp: any) => microApp.enabled)
        .filter((microApp: any) => {
          if (!microApp.permissions) {
            return true;
          }
          return (microApp.permissions.some((permission: any) => loggedInUserPermissions.includes(permission)));
        })
        .forEach((microApp: any) => {
          try {
            performMicroAppRegistration(microApp);
            // eslint-disable-next-line no-console
            console.log(`Registered micro-app ${microApp.name}.`);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(`Failed to register micro-app ${microApp.name}.`);
            // eslint-disable-next-line no-console
            console.error(error); // TODO: this error should be sent out to the dead letter store.
          }
        });
      return registry;
    });
};

export default registerMicroApps;
