import get from 'lodash/get';
import { connect } from 'react-redux';

import { loadPropertyList, updateSelectedProperty } from '../../actions';
import PropertyPickerDropdown from '../../components/PropertyPickerDropdown';
import getClientSwitch from '../../main-apollo-client';
import propertyListLoader from '../../utils/propertyListLoader';

export const getPropertyName = (property: any) => get(property, 'name', null);

export const mapStateToProps = ({ propertyList, propertyListLoading, property }: any) => ({
  properties: propertyList,
  loading: propertyListLoading,
  property,
});

export const mapDispatchToProps = (dispatch: any) => ({
  onPropertyChange: (property: any) => dispatch(updateSelectedProperty(property)),
  loadPropertyList: (force: any, auth: any) => dispatch(loadPropertyList(propertyListLoader(getClientSwitch()(auth)), force)),
});
const PropertyPickerDropdownConnectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyPickerDropdown);

export default PropertyPickerDropdownConnectedContainer;
