import {
  ACTIVE_SIDEBAR_REMOVE,
  ACTIVE_SIDEBAR_SET,
} from '../types';

export const HELP_SIDEBAR = 'HELP_SIDEBAR'; // This isn't real, but maybe?
export const USER_SIDEBAR = 'USER_SIDEBAR';
export const MICRO_APP_SIDEBAR = 'MICRO_APP_SIDEBAR';

export const setActiveSidebar = (name: any, data: any) => ({
  type: ACTIVE_SIDEBAR_SET,
  data: {
    name,
    custom: { ...data },
  },
});

export const removeActiveSidebar = () => ({
  type: ACTIVE_SIDEBAR_REMOVE,
  data: {},
});
