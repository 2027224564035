/* eslint-disable @typescript-eslint/default-param-last */
import { LOAD_PROPERTY_LIST_ERROR, LOAD_PROPERTY_LIST_START, LOAD_PROPERTY_LIST_SUCCESS } from '../../actions/types';

export const propertyListEmptyState = null;
export const propertyList = (state = propertyListEmptyState, action: any) => {
  switch (action.type) {
    case LOAD_PROPERTY_LIST_START:
      return state;
    case LOAD_PROPERTY_LIST_SUCCESS:
      return [...action.data];
    case LOAD_PROPERTY_LIST_ERROR:
      return propertyListEmptyState;
    default:
      return state;
  }
};

export const loadingEmptyState = false;
export const propertyListLoading = (state = loadingEmptyState, action: any) => {
  switch (action.type) {
    case LOAD_PROPERTY_LIST_START:
      return true;
    case LOAD_PROPERTY_LIST_SUCCESS:
      return loadingEmptyState;
    case LOAD_PROPERTY_LIST_ERROR:
      return loadingEmptyState;
    default:
      return state;
  }
};

export const errorEmptyState = false;
export const propertyListError = (state = errorEmptyState, action: any) => {
  switch (action.type) {
    case LOAD_PROPERTY_LIST_START:
      return errorEmptyState;
    case LOAD_PROPERTY_LIST_SUCCESS:
      return errorEmptyState;
    case LOAD_PROPERTY_LIST_ERROR:
      return action.data;
    default:
      return state;
  }
};
