const getPermissionsFromRoleArray = (roles: any) => (
  [...(new Set(roles.reduce((accumulator: any, curentRole: any) => {
    accumulator.push(...curentRole.permissions);
    return accumulator;
  }, [])))]
);

// combines class level permissions into a single list
export const getPermissions = (roles: any) => {
  if (Array.isArray(roles)) {
    return getPermissionsFromRoleArray(roles);
  }
  const result: any = Object.values(roles).reduce((accumulator: any, currentRole: any) => {
    accumulator.push(...currentRole.perms.filter((aPerm: any) => !aPerm.endsWith('_I')));
    return accumulator;
  }, []);
  return [...(new Set(result))];
};

export const getRefreshTokenFrequency = (auth: any) => {
  const refreshValues = Object.values(auth.getRoles()).filter((each: any) => Number.isInteger(each.refresh))
    .map((each: any) => each.refresh);
  return refreshValues.length ? Math.min(...refreshValues) : undefined;
};

export const decodePayload = (jwtToken: any) => {
  const jwtPayload = jwtToken.split('.')[1];
  try {
    return JSON.parse(atob(jwtPayload));
  } catch (err) {
    return {};
  }
};

export const logLogo = () => console.log(`
        ████╗
         ██╔╝
         ██║
███████████████████╗
██╔══════════════██║
██║  ██╗    ██╗  ██║
██║  ╚═╝    ╚═╝  ██║
████╗          ████║
╚═══╝          ╚═══╝
`);
