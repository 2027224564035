import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutiFrame = (props: any) => {
  const { onLoad } = props;
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
    onLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
    </>
  );
};

LogoutiFrame.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

export default LogoutiFrame;
