import { connect } from 'react-redux';

import { logout, removeActiveSidebar } from '../../actions';
import { USER_SIDEBAR } from '../../actions/activeSidebar';
import UserMenuComponent from '../../components/UserMenu';

export const mapStateToProps = ({ activeSidebar }, { visible }) => ({
  visible: visible != null ? visible : (activeSidebar != null && activeSidebar.name === USER_SIDEBAR),
});

export default connect(mapStateToProps, { removeActiveSidebar, logout })(UserMenuComponent);
